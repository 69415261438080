import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from '@/store/app'
import appConfig from '@/store/app-config'
import verticalMenu from '@/store/vertical-menu'
import customers from '@/store/customers'
import customerRelatedPerson from '@/store/customer_related_person/customer_related_person'
import cars from '@/store/cars'
import swapcars from '@/store/swapcars'
import crmRequests from '@/store/crmRequests/index'
import crmRequestFiles from '@/store/crmRequests/files'
import crmAnswers from '@/store/crmAnswers'
import crmSolutions from '@/store/crmSolutions'
import users from '@/store/users'
import userTokens from '@/store/users/userTokens'
import abilities from '@/store/users/abilities'
import alerts from '@/store/alerts'
import notifications from '@/store/notifications'
/* Defines */
import banks from '@/store/defines/banks'
import brands from '@/store/defines/brands'
import swapbrands from '@/store/defines/swapbrands'
import swapmodels from '@/store/defines/swapmodels'
import colors from '@/store/defines/colors'
import carType from '@/store/defines/car_type'
import fuels from '@/store/defines/fuels'
import gears from '@/store/defines/gears'
import departments from '@/store/defines/departments'
import crmSubjects from '@/store/defines/crm_subjects'
import feedbackTypes from '@/store/defines/feedback_types'
import crmLevels from '@/store/defines/crm_levels'
import crmStatuses from '@/store/defines/crm_statuses'
import crmApplySources from '@/store/defines/crm_applysources'
import locations from '@/store/defines/locations'
import meets from '@/store/defines/meets'
import meetsGroups from '@/store/defines/meet_groups'
import userTypes from '@/store/defines/user_types'
import hrNotificationTypes from '@/store/defines/hr_notification_types'
import cities from '@/store/defines/cities'
import districts from '@/store/defines/districts'
import regions from '@/store/defines/regions'
import relatedPersonType from '@/store/defines/related_person_type'
import neighborhoods from '@/store/defines/neighborhoods'
import customerTypes from '@/store/defines/customer_types'
import bulletinTypes from '@/store/defines/bulletin_types'
import jobs from '@/store/defines/jobs'
import sectors from '@/store/defines/sectors'
import productTypes from '@/store/defines/product_types'
import productGroups from '@/store/defines/product_groups'
import productUnits from '@/store/defines/product_units'
import permitRequestStatus from '@/store/defines/permit_request_status'
import permitRequestType from '@/store/defines/permit_request_type'
import suppliers from '@/store/defines/suppliers'
import purchasingStatuses from '@/store/defines/purchasing_statuses'
import interviewTypes from '@/store/defines/interview_types'
import interestRate from '@/store/defines/interest_rate'
import models from '@/store/defines/models'
import statuses from '@/store/defines/statuses'
import interviewStatuses from '@/store/defines/interview_statuses'
import tireBrands from '@/store/defines/tire_brands'
import tireShelfs from '@/store/defines/tire_shelfs'
import tireSizes from '@/store/defines/tire_sizes'
import tireStatuses from '@/store/defines/tire_statuses'
import tireTypes from '@/store/defines/tire_types'
import kdvRates from '@/store/defines/kdv_rates'
import otvRates from '@/store/defines/otv_rates'
import payments from '@/store/defines/payments'
import insuranceCompanies from '@/store/defines/insurance_companies'
import serviceTypes from '@/store/defines/service_types'
import serviceStatuses from '@/store/defines/service_statuses'
import serviceSmsTypes from '@/store/defines/service_sms_types'
import interviewSubjects from '@/store/defines/interview_subjects'
import sparepartOrderTypes from '@/store/defines/sparepart_order_types'
import interviewPolls from '@/store/defines/interview_polls'
import realtyProjects from '@/store/defines/realty_projects'
import realtyTypes from '@/store/defines/realty_types'
import realtyNumbers from '@/store/defines/realty_numbers'
import realtyBlocks from '@/store/defines/realty_blocks'
import realtySaleStatuses from '@/store/defines/realty_sale_statuses'
import rentalStockStatus from '@/store/defines/rental_stock_status'
import rentalContractStatus from '@/store/defines/rental_contract_status'
import rentalInsuranceTypes from '@/store/defines/rental_insurance_types'
import rentalDocumentTypes from '@/store/defines/rental_document_types'
import sparepartOrderStatus from '@/store/defines/sparepart_order_status'
import saleDocumentTypes from '@/store/defines/sale_document_types'
import stockStatuses from '@/store/defines/stock_statuses'
/* Marketing */
import marketingAdTypes from '@/store/marketing/marketing_ad_types'
import marketingAdPlatforms from '@/store/marketing/marketing_ad_platforms'
import marketingAdForms from '@/store/marketing/marketing_ad_forms'
import marketingAds from '@/store/marketing/marketing_ads'
import marketingAdMedia from '@/store/marketing/marketing_ad_media'
import birthdaySms from '@/store/birthdaySms'
import campaignSms from '@/store/campaignSms'
import marketingBatchInterviews from '@/store/marketing/marketing_batch_interviews'
/* Reports */
import requestsReport from '@/store/reports/crm/requestsReport'
import TracingPriceRequests from '@/store/reports/crm/TracingPriceRequests'
import requestDurations from '@/store/reports/crm/requestDurations'
import salesGpr from '@/store/reports/sales/salesGpr'
import salesManagerGpr from '@/store/reports/sales/salesManagerGpr'
import saleUsers from '@/store/reports/sales/saleUsers'
import digitalReport from '@/store/reports/sales/digitalReport'
import g2SurveysReport from '@/store/reports/crm/g2SurveysReport'
import saleSurveysReport from '@/store/reports/crm/SaleSurveysReport'
import rentalInterviews from '@/store/reports/rental/interviews'
import rentalRenewals from '@/store/reports/rental/renewals'
import rentalExpense from '@/store/reports/rental/expense'
import marketingAdsReport from '@/store/reports/marketing/ads'
import marketingAutomotiveReport from '@/store/reports/marketing/automotive'
import marketingSaleMeetReport from '@/store/reports/marketing/meet_report'
import salesInterviews from '@/store/reports/sales/interviews'
import salesDigitalResponse from '@/store/reports/sales/digitalResponse'
import salesStocksReport from '@/store/reports/sales/stocks'
import insuranceProductionReport from '@/store/reports/insurance/production'
import insuranceRenewalsReport from '@/store/reports/insurance/renewals'
import insuranceSalesReport from '@/store/reports/insurance/sales'
import bcsSmsReport from '@/store/reports/service/bcs_sms'
import birthdaySmsReport from '@/store/reports/service/birthday_sms'
import reportServiceTracing from '@/store/reports/service/service_tracing'
import csiBrandsReport from '@/store/reports/csi/csiBrands'
import csiUsersReport from '@/store/reports/csi/csiUsers'
import salesByCity from '@/store/reports/sales/salesByCity'
import saleInvoices from '@/store/reports/sales/saleInvoices'
import loyaltyProgram from '@/store/reports/crm/loyaltyProgram'
import rentalDocumentsReport from '@/store/reports/rental/documents'
import CustomerKvkkReport from '@/store/reports/crm/customerKvkkReport'
import UnreachableRequests from '@/store/reports/crm/UnreachableRequests'
/* External Data */
import serviceCounters from '@/store/external_data/service_counters'
import csi from '@/store/external_data/csi'
/* HR */
import hrNotifications from '@/store/hr/hr_notifications'
import hrEmployees from '@/store/hr/hr_employees'
import hrCareerForm from '@/store/hr/careerform'
/* Insurance */
import insuranceRenewals from '@/store/insurance/renewal'
import insuranceRenewalPeriods from '@/store/insurance/renewal_periods'
import insuranceInterviewBatch from '@/store/insurance/interview_batch'
// Dashboards
/* CRM */
import crmDashboard from '@/store/dashboard/crm/dashboard'
// Common
import customersDashboard from '@/store/dashboard/common/customers'
import interviewsDashboard from '@/store/dashboard/common/interviews'
import alertsDashboard from '@/store/dashboard/common/alerts'
import requestsDashboard from '@/store/dashboard/common/requests'
import salesDashboard from '@/store/dashboard/common/sales'
import g2SalesDashboard from '@/store/dashboard/common/g2Sales'
import expertiseRequestsDashboard from '@/store/dashboard/common/expertiseRequests'
import birthdaySmsDashboard from '@/store/dashboard/common/birthdaySms'
import smsCampaignsDashboard from '@/store/dashboard/common/smsCampaigns'
import servicesDashboard from '@/store/dashboard/common/services'
import sparePartOrdersDashboard from '@/store/dashboard/common/sparePartOrders'
import rentalContractsDashboard from '@/store/dashboard/common/rentalContracts'
import realtySalesDashboard from '@/store/dashboard/common/realtySales'
import insuranceRenewalsDashboard from '@/store/dashboard/common/insuranceRenewals'
import interviewListDashboard from '@/store/dashboard/common/interviewList'
import alertListDashboard from '@/store/dashboard/common/alertList'
import serviceListDashboard from '@/store/dashboard/common/serviceList'
import salesListDashboard from '@/store/dashboard/common/salesList'
import g2SalesListDashboard from '@/store/dashboard/common/g2SalesList'
import rentalContractListDashboard from '@/store/dashboard/common/rentalContractList'
import dashboardFilter from '@/store/dashboard/dashboard_filter'
/* INTERVIEWS */
import interviews from '@/store/interviews'
/* PURCHASING */
import purchasingRequests from '@/store/purchasing/requests'
import purchasingOrders from '@/store/purchasing/orders'
/* SALES */
import saleOrderforms from '@/store/sales/sale_orderforms'
import saleOrderformsCancelPendings from '@/store/sales/sale_orderform_cancel_pendings'

import stock from '@/store/sales/stock'
import saleGoals from '@/store/sales/sale_goals'
import consultantGoals from '@/store/sales/consultant_goals'
import saleDelivery from '@/store/sales/sale_delivery'
import completedSaleDelivery from '@/store/sales/completed_sale_delivery'
import saleDocuments from '@/store/sales/documents'
/* G2 SALES */
import g2sales from '@/store/g2sales/g2sales'
import g2surveys from '@/store/crmRequests/g2Surveys'
import saleSurveys from '@/store/crmRequests/SaleSurveys'
import g2stock from '@/store/g2sales/g2stock'
/* Realty Sales */
import realtySales from '@/store/realtySales/sales'
/* Spare Part Orders */
import sparePartOrders from '@/store/spareParts/orders'
import tireHotel from '@/store/spareParts/tire_hotel'
/* PORTAL */
import awards from '@/store/portal/awards'
import notices from '@/store/portal/notices'
import quoteOfDays from '@/store/portal/quote_of_days'
import meals from '@/store/portal/meals'
import events from '@/store/portal/events'
import polls from '@/store/portal/polls'
import reportage from '@/store/portal/reportage'
import career from '@/store/portal/career'
import gallery from '@/store/portal/gallery'
import eduCalendar from '@/store/portal/edu_calendar'
import educations from '@/store/portal/educations'
import fixedEducations from '@/store/portal/fixed_educations'
import fixedEducationTypes from '@/store/portal/fixed_education_types'
import documents from '@/store/portal/documents'
import contractedCompanies from '@/store/portal/contracted_companies'
/* SERVICE */
import serviceTracing from '@/store/service/index'
import serviceCoupons from '@/store/service/serviceCoupons'
import bcsCampaign from '@/store/service/bcsCampaign'
import serviceSms from '@/store/service/serviceSms'
/* RENTAL */
import rentalStocks from '@/store/rental/stocks'
import rentalContracts from '@/store/rental/contracts'
import rentalDocuments from '@/store/rental/documents'
import rentalExpenses from '@/store/rental/expenses'
import rentalKasko from '@/store/rental/kasko'
import rentalNoter from '@/store/rental/noter'
import rentalDamgaVergisi from '@/store/rental/damga_vergisi'
import rentalInsurances from '@/store/rental/insurances'
import rentalVehiclePark from '@/store/rental/vehicle_park'
/* WEBFORMS */
import webformCallform from '@/store/webforms/callform'
import webformContact from '@/store/webforms/contact'
import webformG2bidform from '@/store/webforms/g2bidform'
import webformG2buyform from '@/store/webforms/g2buyform'
import webformG2saleform from '@/store/webforms/g2saleform'
import webformTestdrive from '@/store/webforms/testdrive'
import webformDigitalResponse from '@/store/webforms/digitalResponse'

/* SMTP */
import smtpConfig from '@/store/smtp'
/* Service Work Orders */
import swoSections from '@/store/serviceWorkOrders/defines/sections'
import swoWorkers from '@/store/serviceWorkOrders/defines/workers'
import swoWorks from '@/store/serviceWorkOrders/defines/works'
import serviceWorkOrders from '@/store/serviceWorkOrders/orders/index'
import serviceWorkOrderLines from '@/store/serviceWorkOrders/orders/lines'

/* Permit Requests */
import permitRequest from '@/store/permit_request'

/* OFFERS */
import offers from '@/store/offers'
import offerLines from '@/store/offers/offer_lines'
import offerLinePriceItems from '@/store/offers/offer_line_price_items'

/* Map Scores */
import mapScores from '@/store/mapScores/index'

/* Manager Tools */
import customerDeduplication from '@/store/managerTools/customer_deduplication'
import interviewCloses from '@/store/managerTools/interviewClose'
import interviewTransfer from '@/store/managerTools/interviewTransfer'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    abilities,
    customers,
    customerRelatedPerson,
    cars,
    swapcars,
    crmRequests,
    crmRequestFiles,
    crmAnswers,
    crmSolutions,
    userTypes,
    hrNotificationTypes,
    meets,
    meetsGroups,
    banks,
    brands,
    swapbrands,
    swapmodels,
    colors,
    carType,
    fuels,
    gears,
    departments,
    crmSubjects,
    feedbackTypes,
    crmLevels,
    crmStatuses,
    crmApplySources,
    realtyProjects,
    realtyTypes,
    realtyNumbers,
    realtyBlocks,
    realtySaleStatuses,
    locations,
    users,
    userTokens,
    requestsReport,
    TracingPriceRequests,
    salesGpr,
    salesManagerGpr,
    saleUsers,
    digitalReport,
    g2SurveysReport,
    saleSurveysReport,
    rentalInterviews,
    rentalRenewals,
    rentalExpense,
    serviceCounters,
    salesInterviews,
    csi,
    hrNotifications,
    hrEmployees,
    hrCareerForm,
    cities,
    districts,
    regions,
    relatedPersonType,
    neighborhoods,
    customerTypes,
    bulletinTypes,
    jobs,
    sectors,
    marketingAdTypes,
    marketingAdPlatforms,
    marketingAdForms,
    marketingAds,
    marketingAdMedia,
    marketingBatchInterviews,
    marketingAdsReport,
    marketingAutomotiveReport,
    marketingSaleMeetReport,
    crmDashboard,
    productTypes,
    productGroups,
    productUnits,
    suppliers,
    purchasingStatuses,
    alerts,
    notifications,
    insuranceRenewals,
    insuranceRenewalPeriods,
    insuranceInterviewBatch,
    interviews,
    interviewTypes,
    interestRate,
    models,
    statuses,
    interviewStatuses,
    purchasingRequests,
    purchasingOrders,
    awards,
    notices,
    quoteOfDays,
    meals,
    events,
    polls,
    reportage,
    career,
    gallery,
    eduCalendar,
    educations,
    fixedEducations,
    fixedEducationTypes,
    documents,
    contractedCompanies,
    tireBrands,
    tireShelfs,
    tireSizes,
    tireStatuses,
    tireTypes,
    kdvRates,
    otvRates,
    insuranceCompanies,
    payments,
    serviceTypes,
    serviceSmsTypes,
    saleOrderforms,
    saleOrderformsCancelPendings,
    interviewSubjects,
    sparepartOrderTypes,
    g2stock,
    interviewPolls,
    stock,
    saleGoals,
    consultantGoals,
    saleDelivery,
    salesDigitalResponse,
    realtySales,
    completedSaleDelivery,
    saleDocuments,
    g2sales,
    g2surveys,
    saleSurveys,
    salesStocksReport,
    insuranceProductionReport,
    insuranceRenewalsReport,
    insuranceSalesReport,
    requestDurations,
    bcsSmsReport,
    birthdaySmsReport,
    reportServiceTracing,
    sparePartOrders,
    tireHotel,
    birthdaySms,
    campaignSms,
    csiBrandsReport,
    csiUsersReport,
    salesByCity,
    saleInvoices,
    loyaltyProgram,
    rentalDocumentsReport,
    serviceTracing,
    serviceStatuses,
    rentalStocks,
    rentalContracts,
    rentalStockStatus,
    rentalContractStatus,
    rentalInsuranceTypes,
    rentalDocumentTypes,
    sparepartOrderStatus,
    saleDocumentTypes,
    stockStatuses,
    rentalDocuments,
    rentalExpenses,
    rentalKasko,
    rentalNoter,
    rentalDamgaVergisi,
    rentalInsurances,
    rentalVehiclePark,
    webformCallform,
    webformContact,
    webformG2bidform,
    webformG2buyform,
    webformG2saleform,
    webformTestdrive,
    webformDigitalResponse,
    serviceCoupons,
    bcsCampaign,
    serviceSms,
    smtpConfig,
    customersDashboard,
    interviewsDashboard,
    alertsDashboard,
    requestsDashboard,
    salesDashboard,
    g2SalesDashboard,
    expertiseRequestsDashboard,
    birthdaySmsDashboard,
    smsCampaignsDashboard,
    servicesDashboard,
    sparePartOrdersDashboard,
    rentalContractsDashboard,
    realtySalesDashboard,
    insuranceRenewalsDashboard,
    interviewListDashboard,
    alertListDashboard,
    serviceListDashboard,
    salesListDashboard,
    g2SalesListDashboard,
    rentalContractListDashboard,
    dashboardFilter,
    swoSections,
    swoWorkers,
    swoWorks,
    serviceWorkOrders,
    serviceWorkOrderLines,
    CustomerKvkkReport,
    UnreachableRequests,
    permitRequest,
    permitRequestStatus,
    permitRequestType,
    offers,
    offerLines,
    offerLinePriceItems,
    customerDeduplication,
    interviewCloses,
    interviewTransfer,
    mapScores,
  },
  strict: process.env.DEV,
})
